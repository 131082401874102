import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../css/login.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { baseUrl, access_token } from "../../services/api";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import firebase from "../../services/firebaseConfig";
import "firebase/compat/storage";
import {
  addBookFamousPerson,
  getFamousPersons,
  getMoniroFeaturedFamousPersons,
  removeBookFamousPerson,
  updateFamousPersonState,
  searchFamousPersons,
} from "../../services/books.service";
import Switch from "react-input-switch";
import SearchBook from "../components/SearchBook";
import { nanoid } from "nanoid";
import { Table, Container, Row, Col } from 'react-bootstrap';
export default function AddFamousPersonForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [quote, setQuote] = useState("");
  const [title, setTitle] = useState("");
  const [search, setSearch] = useState(false);
  const [imageAsFile, setImageAsFile] = useState();
  const [open, setOpen] = React.useState(false);
  const [famousPersons, setFamousPersons] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState("");
  const [selectedPersonEdit, setSelectedPersonEdit] = useState();
  const [featuredFamousPersons, setFeaturedFamousPersons] = useState([]);
  const showNextButton = famousPersons.length > 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFamousPersons, setFilteredFamousPersons] = useState([]);
  const [personType, setPersonType] = useState("");
  const [category, setCategory] = useState([]);
  let imageUrls;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    try {
      const urlll = imageAsFile
        ? await uploadImage(imageAsFile)
        : selectedPersonEdit.imageUrl;
      var profileData = {
        famousPersonName: firstName.trim() + " " + lastName.trim(),
        quote: quote.trim(),
        imageUrl: urlll,
        title: title,
        category: category, // Include category
      };
      if (profileData.imageUrl === "upload failed") {
        console.log("Error on uploading file");
        // alert("image uploading is not working")
        // return;
      }
      updateFamousPersonState(selectedPersonEdit._id, "", "", profileData)
        .then((response) => {
          if (selectedPersonEdit.featured) {
            setFeaturedFamousPersons(
              featuredFamousPersons.map((ddd) =>
                ddd._id === selectedPersonEdit._id
                  ? { ...ddd, ...profileData }
                  : ddd
              )
            );
          } else {
            setFamousPersons(
              famousPersons.map((ddd) =>
                ddd._id === selectedPersonEdit._id
                  ? { ...ddd, ...profileData }
                  : ddd
              )
            );
          }
          clearEdit();
          alert("Updated!");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("No internet connection");
      }
    }
    setOpen(false);
  };
  const clearEdit = () => {
    setFirstName("");
    setLastName("");
    setQuote("");
    setTitle("");
    setCategory(""); // Clear category
    setImageAsFile(undefined);
    setIsEditing(false);
    setSelectedPersonEdit(undefined);
  };

  const handleCreate = async () => {

    try {
      const urlll = await uploadImage(imageAsFile);
      const profileData = {
        famousPersonName: `${firstName.trim()} ${lastName.trim()}`,
        quote: quote.trim(),
        imageUrl: urlll,
        title: title,
        category: category, 
        books: [],
      };
  
      if (profileData.imageUrl === "upload failed") {
        console.log("upload failed ");
        // return;
      }
  
      await axios
        .post(`${baseUrl}/admin/addFamousPerson`, profileData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          setFamousPersons([
            ...famousPersons,
            getMappedFamousPerson([response.data])[0],
          ]);
          clearEdit();
          alert("Added famous person!");
        })
        .catch((err) => {
          console.log("Error adding famous person:", err);
        });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("No internet connection");
      }
    }
    setOpen(false);
  };
  
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image && image.size > 6 * 1024 * 1024) {
      alert("Please select an image with a size below 6MB.");
      return; // Don't proceed if the image size is too large
    }
    setImageAsFile(image);
  };
  const getMappedFamousPerson = (data) =>
    data.map(
      ({
        _id,
        userInfo,
        famousPersonName,
        quote,
        title,
        imageUrl,
        books,
        featured,
        category,
      }) => {
        const { userName, userProfileUrl } =
          (userInfo && userInfo.length > 0) ? userInfo[0].userInfo : {};
        const userType =
          (userInfo && userInfo.length > 0) ? userInfo[0].usertype : '';
        return {
          _id,
          famousPersonName,
          quote,
          title,
          userName,
          imageUrl,
          featured,
          userProfileUrl,
          books: books ?? [],
          category: category ?? 'Author',
        };
      }
    );


  useEffect(() => {
    // Fetch non-featured famous persons

    getFamousPersons()
      .then((response) => {
        const persons = getMappedFamousPerson(response.data); // Map response data
        console.log("Response for non-featured persons:", persons);
        // Set only non-featured famous persons
        setFamousPersons(persons.filter(({ featured }) => !featured));
      })
      .catch((error) => {
        console.error("Error fetching famous persons:", error);
      });


    // Fetch featured famous persons
    getMoniroFeaturedFamousPersons()
      .then((response) => {
        const featuredPersons = getMappedFamousPerson(response.data); // Map response data
        console.log("Response for featured persons:", featuredPersons);
        // Set featured famous persons
        setFeaturedFamousPersons(featuredPersons);
      })
      .catch((error) => {
        console.error("Error fetching featured famous persons:", error);
      });
  }, []);


  const uploadImage = async (image) => {
    try {
      // await handleUpload(image);

      // Your code for getting the download URL directly from Firebase Storage
      var user = firebase.auth().currentUser;
      // if (user != null) {
      let str = selectedPersonEdit?.imageUrl;
      const shortid = `img_${str
        ? str.substring(str.indexOf("img_") + 4, str.indexOf("_author"))
        : nanoid()
        }_author`;
      const path = `/featuredAuthorImage/${shortid}`;
      const storage = firebase.storage();
      const ref = storage.ref().child(path);
      await ref.put(image);
      const url = await ref.getDownloadURL();
      return url;
      // }
      // return null;
    } catch (error) {
      console.error("Image upload failed:", error);
      return "upload failed";
    }
  };

  const fetchNextPage = () => {
    const nextPage = currentPage + 1;
    getFamousPersons(nextPage)
      .then((response) => {
        const newPersons = getMappedFamousPerson(response.data).filter(({ featured }) => !featured);
        setFamousPersons((prev) => [...prev, ...newPersons]);
        setCurrentPage(nextPage);
      })
      .catch((error) => console.error("Error fetching next page:", error));
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    try {

      if (query) {
        const response = await searchFamousPersons(query, 1, 20); // Fetch page 1, limit 20
        const data = response.data; // Assuming response.data contains the famous persons list


        setFilteredFamousPersons(data);
        console.log('searching data', data)
      } else {
        // If query is empty, reset to show all famous persons
        setFilteredFamousPersons(famousPersons);
      }
    } catch (error) {
      console.error("Error fetching famous persons:", error);
    }
  };

  return (
    <div className="maincontainer">
      <div class="container-fluid">
        <div class="row no-gutter">
          <div class="col-md-12 ">
            <div class="col-md-9  ">
              <div class="login d-flex align-items-center py-5">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-10 col-xl-12 mx-auto">
                      <center>
                        <h1 class="display-4" style={{ color: "black" }}>
                          {selectedPersonEdit ? "Edit" : "Add"} Famous Person
                        </h1>
                      </center>
                      <Form>
                        <Form.Group className="mb-6" controlId="exampleForm.ControlInput2">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            style={{ border: '1px solid grey', borderRadius: 5 }}
                            id="firstName"
                            type="text"
                            placeholder="Enter First Name"
                            required
                            autoFocus
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-6" controlId="exampleForm.ControlInput2">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            style={{ border: '1px solid grey', borderRadius: 5 }}
                            id="lastName"
                            type="text"
                            placeholder="Enter Last Name"
                            required
                            autoFocus
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-6" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            id="quote"
                            type="text"
                            placeholder="Enter Quote"
                            required
                            autoFocus
                            maxLength={500}
                            rows={5}
                            value={quote}
                            onChange={(e) => setQuote(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-6" controlId="exampleForm.ControlInput2">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            style={{ border: '1px solid grey', borderRadius: 5 }}
                            id="title"
                            type="text"
                            placeholder="Enter Title"
                            required
                            autoFocus
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-6" controlId="exampleForm.ControlSelectCategory">
  <Form.Label>Category</Form.Label> <br/>
  <small style={{ color: 'red' }}>Hold Cmd (Mac) or Ctrl (Windows) to select multiple categories</small>
  <Form.Control
    as="select"
    multiple
    id="category"
    value={category}
    onChange={(e) => {
      const options = Array.from(e.target.selectedOptions, (option) => option.value);
      console.log('options', options);
      setCategory(options);
    }}
    style={{ border: '1px solid grey', borderRadius: 5 }}
    required
    size={5} // Ensures all options are visible without shift key
  >
    <option value="Author">Author</option>
    <option value="Scientist">Scientist</option>
    <option value="Artist">Artist</option>
    <option value="Singer">Singer</option>
    <option value="Journalist">Journalist</option>
    <option value="Philosopher">Philosopher</option>
    <option value="Inventor">Inventor</option>
    <option value="Leader">Leader</option>
   
  </Form.Control>
</Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Upload Image</Form.Label>
                          <Form.Control
                            id="imageUrl"
                            type="file"
                            accept="image/png, image/jpeg"
                            placeholder={imageUrls}
                            name="imageUrl"
                            required
                            autoFocus
                            onChange={handleImageAsFile}
                          />
                        </Form.Group>

                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill"
                              style={{ backgroundColor: "blue", margin: "20px" }}
                              onClick={isEditing ? handleEdit : handleCreate}
                            >
                              {isEditing ? "Edit" : "Submit"}
                            </div>
                          </div>
                          <div className="col-md-6">
                            {selectedPersonEdit && (
                              <div
                                className="btn btn-danger btn-block text-uppercase mb-2 rounded-pill"
                                style={{ margin: "20px" }}
                                onClick={clearEdit}
                              >
                                Clear
                              </div>
                            )}
                          </div>
                        </div>
                      </Form>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 ">
            <div>
              <h2 className="font-semibold text-slate-800">
                Featured Persons
                {featuredFamousPersons && featuredFamousPersons.length == 0
                  ?

                  <div style={{ marginLeft: '50px', marginTop: '20px' }} className="flex space-x-2">
                    <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-100"></div>
                    <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-200"></div>
                    <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-300"></div>
                  </div>

                  : ""}
              </h2>
              {featuredFamousPersons.length > 0 && (
                <Container fluid>
                  <Row className="justify-content-center">
                    <Col xs={12}>
                      <Table responsive="xl" className="my-6">
                        <thead>
                          <tr>
                            <th>Full Name</th>
                            <th>Add Book</th>
                            <th>Status</th>
                            <th>Featured</th>
                          </tr>
                        </thead>
                        <tbody>
                          {featuredFamousPersons &&
                            featuredFamousPersons.map((famousPerson) => (
                              <tr key={famousPerson._id}>
                                <td style={{ display: "flex", alignItems: "center" }}>
                                  <img
                                    src={famousPerson.imageUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}
                                    alt={famousPerson.famousPersonName}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {famousPerson.famousPersonName}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      setSelectedPerson(famousPerson);
                                      setPersonType("featuredFamousPersons"); // Indicate data type
                                      setSearch(true);
                                    }}
                                  >
                                    Books
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      setIsEditing(true);
                                      setFirstName(famousPerson.famousPersonName.split(" ")[0]);
                                      setLastName(famousPerson.famousPersonName.split(" ")[1]);
                                      setQuote(famousPerson.quote);
                                      setTitle(famousPerson.title);
                                      setCategory(famousPerson.category || "");
                                      setSelectedPersonEdit(famousPerson);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </td>
                                <td>
                                  <Switch
                                    value={"0"}
                                    on="0"
                                    off="1"
                                    onChange={() => {
                                      updateFamousPersonState(famousPerson._id, "featured", false).then((response) => {
                                        setFeaturedFamousPersons(
                                          featuredFamousPersons.filter(({ _id }) => _id !== famousPerson._id)
                                        );
                                        setFamousPersons([
                                          ...famousPersons,
                                          { ...famousPerson, featured: false },
                                        ]);
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>

                    </Col>
                  </Row>
                </Container>

              )}

              <br />
              <br />
              <br />
              <br />

              <div>
                <h2 className="font-semibold text-slate-800">
                  All FamousPersons{" "}
                  {famousPersons && famousPersons.length === 0 ?
                    <div style={{ marginLeft: '50px', marginTop: '20px' }} className="flex space-x-2">
                      <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-100"></div>
                      <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-200"></div>
                      <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-300"></div>
                    </div>

                    : ""}
                </h2>


                <Form.Control
                  type="text"
                  placeholder="Search Famous Persons"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    border: "1px solid grey",
                    borderRadius: 5,
                    marginTop: '20px',
                    marginLeft: '10px',
                    justifyContent: "flex-end",
                    maxWidth: "400px",
                  }}
                />



                <Container fluid>
                  <Row className="justify-content-center">
                    <Col xs={12}>
                      <Table responsive="xl" className="my-6">
                        <thead>
                          <tr>
                            <th>Full Name</th>
                            <th>Add Book</th>
                            <th>Status</th>
                            <th>Featured</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchQuery
                            ? filteredFamousPersons.map((famousPerson) => (
                              <tr key={famousPerson._id}>
                                <td style={{ display: "flex", alignItems: "center" }}>
                                  <img
                                    src={famousPerson.imageUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} // Use default image if no image exists
                                    alt={famousPerson.famousPersonName}
                                    style={{
                                      width: "40px",       // Set the width of the image (adjust as needed)
                                      height: "40px",      // Set the height of the image (adjust as needed)
                                      borderRadius: "50%", // Make the image circular
                                      marginRight: "10px", // Add some space between the image and the name
                                    }}
                                  />
                                  {famousPerson.famousPersonName}
                                </td>

                                <td>
                                  <Button
                                    onClick={() => {
                                      setSelectedPerson(famousPerson);
                                      setSearch(true);
                                    }}
                                  >
                                    Books
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      setIsEditing(true);
                                      setFirstName(famousPerson.famousPersonName.split(" ")[0]);
                                      setLastName(famousPerson.famousPersonName.split(" ")[1]);
                                      setQuote(famousPerson.quote);
                                      setTitle(famousPerson.title);
                                      setCategory(famousPerson.category || "");
                                      setSelectedPersonEdit(famousPerson);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </td>
                                <td>
                                  <Switch
                                    value={"1"}
                                    on="0"
                                    off="1"
                                    onChange={() => {
                                      updateFamousPersonState(
                                        famousPerson._id,
                                        "featured",
                                        true
                                      ).then((response) => {
                                        setFamousPersons(
                                          famousPersons.filter(
                                            ({ _id }) => _id !== famousPerson._id
                                          )
                                        );
                                        setFeaturedFamousPersons([
                                          ...featuredFamousPersons,
                                          { ...famousPerson, featured: true },
                                        ]);
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                            : famousPersons.map((famousPerson) => (
                              <tr key={famousPerson._id}>
                                <td style={{ display: "flex", alignItems: "center" }}>
                                  <img
                                    src={famousPerson.imageUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'} // Use default image if no image exists
                                    alt={famousPerson.famousPersonName}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {famousPerson.famousPersonName}
                                </td>

                                <td>
                                  <Button
                                    onClick={() => {
                                      setSelectedPerson(famousPerson);
                                      setSearch(true);
                                    }}
                                  >
                                    Books
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      setIsEditing(true);
                                      setFirstName(famousPerson.famousPersonName.split(" ")[0]);
                                      setLastName(famousPerson.famousPersonName.split(" ")[1]);
                                      setQuote(famousPerson.quote);
                                      setCategory(famousPerson.category || "");
                                      setTitle(famousPerson.title);
                                      setSelectedPersonEdit(famousPerson);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </td>
                                <td>
                                  <Switch
                                    value={"1"}
                                    on="0"
                                    off="1"
                                    onChange={() => {
                                      updateFamousPersonState(
                                        famousPerson._id,
                                        "featured",
                                        true
                                      ).then((response) => {
                                        setFamousPersons(
                                          famousPersons.filter(
                                            ({ _id }) => _id !== famousPerson._id
                                          )
                                        );
                                        setFeaturedFamousPersons([
                                          ...featuredFamousPersons,
                                          { ...famousPerson, featured: true },
                                        ]);
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <div>
                        {showNextButton && <Button onClick={fetchNextPage}>See More</Button>}
                      </div>
                    </Col>
                  </Row>
                </Container>

              </div>

            </div>
            <Modal
              show={search}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="custom-modal"
            >
              <Modal.Header
                closeButton
                style={{
                  background: "linear-gradient(90deg, #2c3e50, #4ca1af)",
                  color: "#fff",
                }}
              >
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  Add Books
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: "20px", backgroundColor: "#f9f9f9" }}>
                <div
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "20px",
                  }}
                >
                  <SearchBook
                    primaryAction={(book) =>
                      addBookFamousPerson(selectedPerson._id, book._id).then((response) => {
                        const isFeatured = featuredFamousPersons.some(
                          (famousPerson) => famousPerson._id === selectedPerson._id
                        );

                        if (isFeatured) {
                          // Update featuredFamousPersons
                          setFeaturedFamousPersons(
                            featuredFamousPersons.map((famousPerson) =>
                              famousPerson._id === selectedPerson._id
                                ? { ...famousPerson, books: [...famousPerson.books, book] }
                                : famousPerson
                            )
                          );
                        } else {
                          // Update famousPersons
                          setFamousPersons(
                            famousPersons.map((famousPerson) =>
                              famousPerson._id === selectedPerson._id
                                ? { ...famousPerson, books: [...famousPerson.books, book] }
                                : famousPerson
                            )
                          );
                        }
                      })
                    }
                    secondaryAction={(book) =>
                      removeBookFamousPerson(selectedPerson._id, book._id).then((response) => {
                        const isFeatured = featuredFamousPersons.some(
                          (famousPerson) => famousPerson._id === selectedPerson._id
                        );

                        if (isFeatured) {
                          // Update featuredFamousPersons
                          setFeaturedFamousPersons(
                            featuredFamousPersons.map((famousPerson) =>
                              famousPerson._id === selectedPerson._id
                                ? {
                                  ...famousPerson,
                                  books: famousPerson.books.filter(
                                    (bk) => bk._id !== book._id
                                  ),
                                }
                                : famousPerson
                            )
                          );
                        } else {
                          // Update famousPersons
                          setFamousPersons(
                            famousPersons.map((famousPerson) =>
                              famousPerson._id === selectedPerson._id
                                ? {
                                  ...famousPerson,
                                  books: famousPerson.books.filter(
                                    (bk) => bk._id !== book._id
                                  ),
                                }
                                : famousPerson
                            )
                          );
                        }
                      })
                    }
                    books={
                      selectedPerson
                        ? famousPersons
                          .concat(featuredFamousPersons)
                          .find(({ _id }) => _id === selectedPerson._id)?.books ?? []
                        : []
                    }
                  />

                </div>
              </Modal.Body>
              <Modal.Footer
                style={{
                  borderTop: "1px solid #eee",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => {
                    setSearch(!search);
                    setSelectedPerson(undefined);
                  }}
                  style={{
                    backgroundColor: "#ff6f61",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = "#ff5a4f")}
                  onMouseOut={(e) => (e.target.style.backgroundColor = "#ff6f61")}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>


          </div>
        </div>
      </div>
    </div>
  );
}
